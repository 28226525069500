import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	CardText,
} from 'reactstrap';

import T from 'modules/i18n';
import { StaticRoutes, DynamicRoutes } from '../model/routes';
import { buildPath } from 'core/model/lib/urlTools';
import { Loading, SecureContent, Dashboard as Template } from 'core/components';
import * as roles from 'core/model/roles';
import { WorkflowDashboard } from './layout';

class Dashboard extends Component {

	render() {
		let { pending, stats } = this.props;
		if (this.props.role !== roles.GUEST && (pending || (!stats.applications)))
			return (<Loading/>);

		let appCols = [];
		if ( this.props.role !== roles.GUEST ) {
			let keys = Object.keys(stats.applications.status);
			keys.forEach((status, index) => {
				let number = stats.applications.status[status];
				let colIndex = Math.floor(index/Math.ceil(keys.length/2));
				if (!appCols[colIndex])
					appCols[colIndex] = [];
				appCols[colIndex].push(
					<Link key={`link_${status}`} to={buildPath(DynamicRoutes.WorkflowsListStatus, [status])}>
						<CardText className="text-light text-lowercase"><big>{number}</big> <T>{status}</T></CardText>
					</Link>
				);
			});
		}

		return (
			<>
				<Template>
					{ (stats && stats.applications) &&
						<SecureContent role={roles.REVIEWER}>
							<Card>
								<CardHeader>
									<Link to={StaticRoutes.AdminWorkflowsList} className="no-decoration">
										<i className="icon-book-open" /> { stats.applications.total } <T>applications</T>
									</Link>
								</CardHeader>
								<CardBody className="text-white bg-info">
									<Row>
										<Col>{appCols[0]}</Col>
										<Col>{appCols[1]}</Col>
									</Row>
								</CardBody>
							</Card>
						</SecureContent>
					}
				</Template>
				<WorkflowDashboard items={true} />
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	role: state.profile.user.role,
	stats: state.update.response,
	pending: state.update.pending,
});

Dashboard = connect(mapStateToProps)(Dashboard);

export default Dashboard;
