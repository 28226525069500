import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'workflows',
		url: StaticRoutes.AdminWorkflowsList,
		icon: 'fa fa-tasks',
		role: roles.REVIEWER
	},
	{
		name: 'register_application',
		url: StaticRoutes.WorkflowDashboard,
		icon: 'icon-plus',
		role: roles.GUEST
	},
	{
		name: 'workflows',
		url: StaticRoutes.MyWorkflows,
		icon: 'icon-book-open',
		role: roles.GUEST,
	},
	{
		name: 'inactive_workflows',
		url: StaticRoutes.Inactive,
		icon: 'icon-trash',
		role: roles.AUTHORIZED
	}
];

export default menu;
