import React, { Component } from 'react';
import { FormattedDate } from 'react-intl';
import { Badge, UncontrolledDropdown as Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import PropTypes from 'prop-types';

import T from 'modules/i18n';

class Tr extends Component {
	constructor(props) {
		super(props);
		this.state = {selected: props.selectedRow ? props.selectedRow : ''};
	}

	render() {

		const { data, layout, scope, order } = this.props;

		if (scope && scope === 'thead') {
			return (
				<tr>
					{data.map((col, index) => {
						let value = layout && layout[col] && layout[col].sortable ?
							<span role="button" onClick={() => this.props.onClick(col)} className={this.props.className ? this.props.className : undefined}>
								<T>{col}</T> {' '}
								{(this.props.sortBy === col && this.props.sortMethod === 'asc') && <i className="fa fa-sort-asc hidden" aria-hidden="true"></i>}
								{(this.props.sortBy === col && this.props.sortMethod === 'desc') && <i className="fa fa-sort-desc hidden" aria-hidden="true"></i>}
							</span>
							:
							<T>{col}</T>
						return (<th className="text-nowrap" key={`th_col_${index}`}>{value}</th>);
					})}
				</tr>
			);
		}

		let tr = Object.keys(data).map((index) => {
			let value = data[index];
			let iterator = order ? order : Object.keys(value);
			const td = iterator.map((col) => {
				let val = value[col];
				let cell;
				let dropdown = '';
				let cellClass = 'no-wrap';
				if (val === null || !layout || !layout[col]) {
					cell = val;
				} else {
					let type = layout ? layout[col].type : '';
					switch (type) {
						case 'date':
							let plus = val.indexOf('+');
							val = plus !== -1 ? val.substring(0, plus) : val;
							cell =
							<FormattedDate
								value={val}
								year='numeric'
								month='short'
								day='2-digit'
							/>;
							break;

						// case 'area':
						// 	cell = <Units category='area'>{val}</Units>;
						// 	break;

						case 'translatable':
							cell = this.props.badge_colors && this.props.badge_colors[val]
								? <Badge color={this.props.badge_colors[val]}><T>{val}</T></Badge>
								: <T>{val}</T>;
							break;

						case 'boolean':
							cell = this.props.badge_colors && this.props.badge_colors[val]
								? <Badge color={this.props.badge_colors[val]}>{ val ? <i className="fa fa-check px-2"/> : <i className="fa fa-times px-2"/> }</Badge>
								: <span>{ val ? <i className="fa fa-check px-2"/> : <i className="fa fa-times px-2"/> }</span>
							break;

						case 'text':
							cell = <p className="text-justify">{val}</p>
							cellClass = '';
							break;

						default:
							cell = val;
					}
				}
				if (layout && layout[col] && layout[col].select) {
					dropdown = (
						<Dropdown className="inline-dd" direction="down">
							<DropdownToggle caret size="sm" className="no-outline btn-xsm"/>
							<DropdownMenu>
								{ Object.keys(layout[col].select).map((key) =>
									<DropdownItem key={`${key}_${index}`} onClick={() => {
										if (typeof layout[col].onClick === 'function')
											layout[col].onClick(index, layout[col].select[key]);
									}}>
										<T>{layout[col].select[key]}</T>
									</DropdownItem>
								) }
							</DropdownMenu>
						</Dropdown>
					);
				}
				return <td key={col + "_" + index} className={cellClass}>{cell}{dropdown}</td>;
			});
			let icons = React.Children.map(this.props.children, (child) => React.cloneElement(child, {data: {index, ...value}}));
			return (
				<tr
					className={
						this.props.onRowClick || this.props.selectedRow
						? (this.props.onRowClick && "row-selectable") + (this.state.selected===index ? ' row-selected' : '')
						: ''
					}
					id={index}
					key={index}
					onClick={() => {
						if (this.props.onRowClick) {
							this.setState({selected: index});
							this.props.onRowClick(index);
						}
					}}
				>
					{icons ? (<td style={{minWidth: '100px'}}>{icons}</td>) : null}
					{td}
				</tr>
			);
		});
		return tr;
	}
}

Tr.propTypes = {
	data: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]).isRequired,
	layout: PropTypes.object,
	order: PropTypes.array,
	sortBy: PropTypes.string,
	sortMethod: PropTypes.string,
	badge_colors: PropTypes.object,
	changeAttrFn: PropTypes.func,
	onRowClick: PropTypes.func,
};

Tr.displayName = 'Tr';

export default Tr;
