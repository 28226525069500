import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DragDropContext } from 'react-beautiful-dnd';
import { Row, Col, Button } from 'reactstrap';

import { AdvancedView as View } from 'advanced-view';
import { requestData } from 'core/ducks/list';
import { postData, getData } from 'core/ducks/update';
import { toggleModal } from 'core/ducks/ui/modal';
import { List, Job } from '../../components';
import { Loading } from 'core/components';
import T from 'modules/i18n';

class BulkAssign extends Component {

	constructor(props) {
		super(props);
		this.state = {
			jobs: [],
			groups: []
		};
		this.actions = bindActionCreators({toggleModal}, props.dispatch);

		this.onDragEnd = this.onDragEnd.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.viewDetails = this.viewDetails.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if ((prevProps.pending && !this.props.pending) || (prevProps.refreshing && !this.props.refreshing)) {
			this.setState({jobs: this.prepareJobsList(this.props.jobs)});
		}
		if (prevProps.groups && ((prevProps.groups.pending && !this.props.groups.pending) || (prevProps.groups.refreshing && !this.props.groups.refreshing)) ) {
			if (this.props.groups.status === 200) {
				this.setState({groups: this.props.groups.data});
			}
		}
	}

	fetchData() {
		const date = this.getDate(new Date(), 'sql');
		this.props.dispatch( requestData('application', 'application/page/1/sort/created/sort_method/desc/fq/type:public;status:with_editor;node_type:assignation/limit/10000'));
		this.props.dispatch( requestData('groups', `groups/date/${date}`) );
	}

	getGroup(droppableId) {
		let group;
		if (droppableId === 'droppable_area_jobs') {
			group = 'jobs';
		} else {
			group = droppableId.split('_');
			group = group[group.length - 1];
		}
		return group;
	}

	reorder(group, startIndex, endIndex) {
		const result = Array.from(group);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	}

	move(sourceGroup, targetGroup, startIndex, endIndex) {
		const source = Array.from(sourceGroup);
		const target = Array.from(targetGroup);

		const [removed] = source.splice(startIndex, 1);
		target.splice(endIndex, 0, removed);
		return [source, target];
	}

	onDragEnd(result) {
		const { source, destination } = result;

		if (!destination)
			return;

		if (source.droppableId === destination.droppableId) {
			const group = this.getGroup(destination.droppableId);
			let reordered;
			if (group === 'jobs') {
				reordered = this.reorder(this.state.jobs, source.index, destination.index);
				this.setState({jobs: reordered});
			} else {
				reordered = this.reorder(this.state.groups[group].jobs, source.index, destination.index);
				this.setState({
					groups: Object.assign(
						[...this.state.groups],
						{[group]: {
							...this.state.groups[group],
							jobs: reordered
						}}
					)
				});
			}
		} else {
			const sourceGroup = this.getGroup(source.droppableId);
			const targetGroup = this.getGroup(destination.droppableId);
			let sourceReordered, targetReordered;
			if (sourceGroup === 'jobs') {
				[sourceReordered, targetReordered] = this.move(this.state.jobs, this.state.groups[targetGroup].jobs, source.index, destination.index);
				this.setState({
					jobs: sourceReordered,
					groups: Object.assign(
						[...this.state.groups],
						{[targetGroup]: {
							...this.state.groups[targetGroup],
							jobs: targetReordered
						}}
					)
				});
			} else if (targetGroup === 'jobs') {
				return;
			} else {
				[sourceReordered, targetReordered] = this.move(this.state.groups[sourceGroup].jobs, this.state.groups[targetGroup].jobs, source.index, destination.index);
				this.setState({
					groups: Object.assign(
						[...this.state.groups],
						{
							[sourceGroup]: {
								...this.state.groups[sourceGroup],
								jobs: sourceReordered
							},
							[targetGroup]: {
								...this.state.groups[targetGroup],
								jobs: targetReordered
							}
						}
					)
				});
			}
		}
	}

	getDate(date, format='int') {
		const year = date.getFullYear();
		const month = date.getMonth();
		const day = date.getDate();
		const formattedDate = format === 'int' ? year * 1000 + month * 100 + day : `${year}-${month + 1}-${day}`;
		return formattedDate;
	}

	prepareJobsList(items) {
		return Object.keys(items)
			.map(id => {
				let item = items[id];
				item.uuid = id;
				return item;
			});
	}

	handleSubmit() {
		let results = {};
		this.state.groups.forEach(group => {
			group.jobs.forEach(job => {
				results[job.uuid] = {[job.current_node]: group.uuid};
			});
		});
		this.props.dispatch( postData('bulkAssign', results) );
	}

	viewDetails(uuid) {
		this.props.dispatch( getData(`advancedView/application/${uuid}`) ).then(() => {
			if (this.props.http_status === 200)
				this.actions.toggleModal(true,
					<View
						title={this.props.view.title}
						toggle={this.actions.toggleModal}
						data={this.props.view.values}
						location={this.props.view.location}
						details={this.props.view.details}
						translateFields={false}
					/>
				);
		});
	}

	render() {
		const { messages } = this.props.i18n || {messages: {}};
		const { pending, groups } = this.props;

		if (pending || !groups || !groups.data)
			return <Loading />;

		return (
			<div className="grouping">
				<Row>
					<Col className="text-right mx-5">
						<Button color="success" onClick={this.handleSubmit}><T>submit</T></Button>{' '}
						<Button color="secondary" onClick={this.fetchData}><T>reset</T></Button>
					</Col>
				</Row>
				<Row style={{overflowX: 'hidden'}}>
					<DragDropContext onDragEnd={this.onDragEnd}>
						<Col className="mx-5">
							<List droppableId="droppable_area_jobs" title={messages.jobs || 'jobs'}>
								{this.state.jobs.map(job =>
									<Job key={`job_${job.uuid}`} id={job.uuid} identifier={job.primary_registry} comment={job.comment} created={job.created} lastAction={job.last_action} name={job.name} view={this.viewDetails} />
								)}
							</List>
						</Col>
						<Col className="mx-5">
							{this.state.groups.map((group, index) =>
								<List key={`group_${index}`} droppableId={`group_${index}`} title={group.label} members={group.members}>
									{group.jobs.map(job =>
										<Job key={`job_${job.uuid}`} id={job.uuid} identifier={job.primary_registry} comment={job.comment} created={job.created} lastAction={job.last_action} name={job.name} view={this.viewDetails} />
									)}
								</List>
							)}
						</Col>
					</DragDropContext>
				</Row>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
	jobs: state.list.application.data.values,
	pending: state.list.application.pending,
	refreshing: state.list.application.refreshing,
	groups: state.list.groups,
	view: state.update.response,
	http_status: state.update.status,
});

BulkAssign = connect(mapStateToProps)(BulkAssign);

export default BulkAssign;
