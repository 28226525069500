import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Form,
	FormGroup,
	FormFeedback,
	Label,
	Button,
	ButtonGroup
} from 'reactstrap';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { Input } from '../../components';
import { toggleModal } from '../../ducks/ui/modal';
import { getValidation, validate } from '../../ducks/forms';
import { updateData, postData } from '../../ducks/update';
import { pushNotification } from '../../ducks/notifications';
import { Loading } from '../../components';
import * as roles from '../../model/roles';
import T from 'modules/i18n';

class ProfileForm extends Component {

	constructor(props) {
		super(props);
		const data = props.profile ? props.profile_data : (props.data ? props.data : {});
		const initialValues = {
			firstname: data.firstname || '',
			lastname: data.lastname || '',
			email: data.email || '',
			role: data.role || roles.GUEST,
			phone: data.phone || '',
			vat: data.vat || '',
			amka: data.amka || '',
			address: data.address || '',
			zip: data.zip || '',
			area: data.area || ''
		};
		const readOnly = {
			created: data.created ? data.created : '',
			date_last_login: data.last_login ? data.last_login : '',
		}
		if (props.profile || props.data) {
			readOnly.username = data.username;
		} else {
			initialValues.username = '';
		}
		this.initialValues = initialValues;
		this.state = {
			values: initialValues,
			readOnly,
			under_submit: false,
			disabled: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {
		let target = event.target;
		this.setState({
			values: {
				...this.state.values,
				[target.name]: target.value
			},
			under_submit: false
		}, () => {
			if (typeof this.props.unsubmitted === 'function')
				this.props.unsubmitted(JSON.stringify(this.initialValues) !== JSON.stringify(this.state.values));
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch, rules } = this.props;
		this.setState({under_submit: true}, () => dispatch(validate(this.state.values, rules)));
		if (typeof this.props.unsubmitted === 'function')
			this.props.unsubmitted(JSON.stringify(this.initialValues) !== JSON.stringify(this.state.values));
	}

	submit(data) {
		const { dispatch, profile } = this.props;
		if (profile || this.props.data) {
			let url = profile ? 'profile' : `users/uuid/${this.props.data.uuid}`;
			dispatch(updateData(url, data, false)).then(() => {
				this.initialValues = {...this.initialValues, ...data};
				if (this.props.modalOpen)
					dispatch(toggleModal());
			});
		} else {
			dispatch(postData(('registration'), data));
			this.setState({disabled: true});
		}
	}

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(getValidation('userInfo'));
		if (typeof this.props.unsubmitted === 'function')
			this.props.unsubmitted(false);
	}

	componentDidUpdate(prevProps) {
		if ( this.state.under_submit && this.props.valid) {
			const { values } = this.state;
			let data = {};
			if (this.props.profile || this.props.data) {
				Object.keys(values).forEach((key) => {
					if (values[key] !== this.initialValues[key])
						data[key] = values[key];
				});
			} else {
				data = values;
			}
			if (Object.keys(data).length > 0)
				this.submit(data);
			this.setState({under_submit: false});
		}
		if (prevProps.post_pending && !this.props.post_pending) {
			const { post_status, dispatch } = this.props;
			if (post_status === 200) {
				dispatch(pushNotification({body: "user added", type: "success"}));
				this.setState({submitted: true});
			} else if (post_status === '409') {
				dispatch(pushNotification({body: "username exists", type: "warning"}));
			} else {
				dispatch(pushNotification({body: 'action denied', type: 'warning'}));
			}
		}
	}

	render() {
		const { i18n, pending, modalOpen, validation_msgs, dispatch, rules } = this.props;
		const { values, readOnly } = this.state;
		if (pending || Object.keys(this.props.rules).length === 0)
			return (<Loading />);

		let disabled = this.state.disabled ? {disabled: 'disabled'} : {};

		return (
			<Form onSubmit={this.handleSubmit}>
				<fieldset {...disabled}>
					<Row>
						<Col xs="12" md="4">
							<FormGroup>
								<Label htmlFor="username"><T>username</T>*</Label>
								<Input
									id="username"
									type="text"
									name="username"
									value={(this.props.profile || this.props.data) ? readOnly.username : values.username}
									readOnly={(this.props.profile || this.props.data) ? true : undefined}
									required={(this.props.profile || this.props.data) ? undefined : true}
									autoComplete="off"
									maxLength="20"
									onChange={this.handleChange}
									pattern={rules.username.validation}
								/>
								<FormFeedback><T>{validation_msgs.username}</T></FormFeedback>
							</FormGroup>
						</Col>
						<Col xs="12" md="4">
							{(this.props.profile || this.props.data) ?
								<FormGroup>
									<Label htmlFor="created"><T>date_joined</T></Label>
									<Input
										id="created"
										type="datetime"
										value={
											this.props.intl.formatDate(readOnly.created, {
												year: 'numeric',
												month: 'long',
												day: 'numeric'
											}) +
											', ' +
											this.props.intl.formatTime(readOnly.created)
										}
										readOnly
									/>
								</FormGroup>
								:
								<span/>
							}
						</Col>
						<Col xs="12" md="4">
							{(this.props.profile || this.props.data) ?
								<FormGroup>
									<Label htmlFor="date_last_login"><T>date_last_login</T></Label>
									<Input
										id="date_last_login"
										type="datetime"
										value={
											this.props.intl.formatDate(readOnly.date_last_login, {
												year: 'numeric',
												month: 'long',
												day: 'numeric'
											}) +
											', ' +
											this.props.intl.formatTime(readOnly.created)
										}
										readOnly
									/>
								</FormGroup>
								:
								<span/>
							}
						</Col>
					</Row>
					<Row>
						<Col xs="12" md="6" lg="3">
							<FormGroup>
								<Label htmlFor="firstname"><T>firstname</T>*</Label>
								<Input
									id="firstname"
									type="text"
									name="firstname"
									autoComplete="given-name"
									required
									value={values.firstname}
									onChange={this.handleChange}
									pattern={rules.firstname.validation}
								/>
								<FormFeedback><T>{validation_msgs.firstname}</T></FormFeedback>
							</FormGroup>
						</Col>
						<Col xs="12" md="6" lg="3">
							<FormGroup>
								<Label htmlFor="lastname"><T>lastname</T>*</Label>
								<Input
									id="lastname"
									type="text"
									name="lastname"
									autoComplete="family-name"
									value={values.lastname}
									required
									onChange={this.handleChange}
									pattern={rules.lastname.validation}
								/>
								<FormFeedback><T>{validation_msgs.lastname}</T></FormFeedback>
							</FormGroup>
						</Col>
						{ !this.props.profile &&
							<Col xs="12" md="6" lg="3">
								<FormGroup>
									<Label htmlFor="role"><T>role</T></Label>
									<Input
										id="role"
										name="role"
										type="select"
										value={values.role}
										onChange={this.handleChange}
									>
										{ Object.keys(roles).map((key) => <option key={`option_${key}`} value={roles[key]}>{i18n[roles[key]]}</option>) }
									</Input>
								</FormGroup>
							</Col>
						}
					</Row>
					<Row>
						<Col xs="12" lg="6">
							<FormGroup>
								<Label htmlFor="email"><i className="icon-envelope-letter" aria-hidden="true"></i> <T>email</T>*</Label>
								<Input
									id="email"
									type="email"
									name="email"
									autoComplete="email"
									value={values.email}
									required
									onChange={this.handleChange}
									pattern={rules.email.validation}
								/>
								<FormFeedback><T>{validation_msgs.email}</T></FormFeedback>
							</FormGroup>
						</Col>
						<Col xs="12" lg="6">
							<FormGroup>
								<Label htmlFor="phone"><i className="icon-phone" aria-hidden="true"></i> <T>phone</T>*</Label>
								<Input
									id="phone"
									type="text"
									name="phone"
									autoComplete="tel-national"
									value={values.phone}
									onChange={this.handleChange}
									pattern={rules.phone.validation}
								/>
								<FormFeedback><T>{validation_msgs.phone}</T></FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col xs="12" sm="6">
							<FormGroup>
								<Label htmlFor="vat"> <T>vat</T>*</Label>
								<Input
									id="vat"
									type="text"
									name="vat"
									value={values.vat}
									onChange={this.handleChange}
									pattern={rules.vat.validation}
								/>
								<FormFeedback><T>{validation_msgs.vat}</T></FormFeedback>
							</FormGroup>
						</Col>
						<Col xs="12" sm="6">
							<FormGroup>
								<Label htmlFor="amka"> <T>amka</T>*</Label>
								<Input
									id="amka"
									type="text"
									name="amka"
									value={values.amka}
									onChange={this.handleChange}
									pattern={rules.amka.validation}
								/>
								<FormFeedback><T>{validation_msgs.amka}</T></FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col xs="12" lg="6">
							<FormGroup>
								<Label htmlFor="address"> <T>address</T></Label>
								<Input
									id="address"
									type="text"
									name="address"
									autoComplete="street-address"
									value={values.address}
									onChange={this.handleChange}
									pattern={rules.address.validation}
								/>
								<FormFeedback><T>{validation_msgs.address}</T></FormFeedback>
							</FormGroup>
						</Col>
						<Col xs="12" md="6" lg="3">
							<FormGroup>
								<Label htmlFor="zip"> <T>zip</T>*</Label>
								<Input
									id="zip"
									type="text"
									name="zip"
									autoComplete="postal-code"
									value={values.zip}
									onChange={this.handleChange}
									pattern={rules.zip.validation}
								/>
								<FormFeedback><T>{validation_msgs.zip}</T></FormFeedback>
							</FormGroup>
						</Col>
						<Col xs="12" md="6" lg="3">
							<FormGroup>
								<Label htmlFor="area"> <T>area name</T></Label>
								<Input
									id="area"
									type="text"
									name="area"
									value={values.area}
									onChange={this.handleChange}
									pattern={rules.area.validation}
								/>
								<FormFeedback><T>{validation_msgs.area}</T></FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col className="text-right">
							<ButtonGroup>
								<Button
									color="primary"
									type="submit"
								>
									<T>save</T>
								</Button>
								<Button
									color="secondary"
									type="reset"
									onClick={() => {
										if (modalOpen)
											dispatch(toggleModal());
									}}
								>
									<T>cancel</T>
								</Button>
							</ButtonGroup>
						</Col>
					</Row>
				</fieldset>
			</Form>
		);
	};
}

ProfileForm.propTypes = {
	profile: PropTypes.bool,
	data: PropTypes.object,
	unsubmitted: PropTypes.func
};

const mapStateToProps = (state) => ({
	profile_data: state.profile.user,
	pending: state.forms.pending,
	rules: state.forms.validation.rules,
	valid: state.forms.valid,
	validation_msgs: state.forms.validation_msgs,
	post_pending: state.update.sending,
	post_status: state.update.status,
	modalOpen: state.ui.modal.modalOpen,
	i18n: state.i18n.messages,
});

ProfileForm = connect(mapStateToProps)(injectIntl(ProfileForm));

export default ProfileForm;
