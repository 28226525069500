import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup } from 'reactstrap';

import { Input } from 'core/components';

import Map from './map.js';

class Address extends Component {

	constructor(props) {
		super(props);
		this.state = {
			values: {
				street: '',
				house_number: '',
				zip: '',
				town: ''
			},
			coordinates: '',
			geoCoding: {},
			geoCodeID: -1
		};
		this.handleChange = this.handleChange.bind(this);
		this.handlePositionChange = this.handlePositionChange.bind(this);
	}

	componentDidMount() {
		const { value } = this.props;
		const values = value.split('+');
		this.setState({
			values: {
				...this.state.values,
				street: values[0] || '',
				house_number: values[1] || '',
				zip: values[2] || '',
				town: values[3] || ''
			}
		});
	}

	handleChange(event) {
		const { target } = event;
		const values = {
			...this.state.values,
			[target.name]: target.value
		};
		this.setState({values});

		let value = Object.values(values).map((elem) => elem.trim()).join('+');
		let completed = Object.values(values).filter(elem => elem.length > 0).length === 4;
		if (completed) {
			if (this.state.geoCodeID !== -1) {
				clearTimeout(this.state.geoCodeID);
			}
			const geoCodeID = setTimeout(() => {
				const address = encodeURI(value);
				fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBIRAoofCxFJ7ALT8nXqZt-VZWoiUq1Sxk`, {
					method: 'GET'
				}).then(response => {
					return response.json()
				}).then(json => {
					const { lat, lng } = json.results[0].geometry.location;
					this.setState({geoCoding: {lat, lng}});
				});
				this.setState({geoCodeID: -1});
			}, 700);
			this.setState({geoCodeID});
		}
		if (typeof this.props.onChange == 'function')
			this.props.onChange({target: {name: this.props.name, value: {address: value, location: this.state.coordinates}}});
	}

	handlePositionChange(event) {
		this.setState({coordinates: event.target.value});
	}

	i18n = {
		'en': {
			street: 'Street',
			number: 'Number',
			town: 'Town',
			zip: 'Zip'
		},
		'el': {
			street: 'Οδός',
			number: 'Αριθμός',
			town: 'Πόλη',
			zip: 'Ταχ. Κωδικός'
		}
	}

	render() {
		let { onChange, name, value, id, locale, ...inputProps } = this.props;
		locale = locale || 'en';
		const { values } = this.state;
		return (
			<FormGroup>
				<FormGroup tag="fieldset">
					<Row form >
						<Col md="10" lg="6">
							<Input id="address_street" {...inputProps} placeholder={this.i18n[locale].street} name="street" value={values.street} onChange={this.handleChange} pattern="[a-zA-Zα-ωΑ-Ωέύϋΰίϊΐόάήώ.\ ]+" />
						</Col>
						<Col md="2" lg="1">
							<Input id="address_house_number" {...inputProps} placeholder={this.i18n[locale].number} name="house_number" value={values.house_number} onChange={this.handleChange} pattern="[0-9]+[ΑΒΓΔαβγδ]{0,1}" />
						</Col>
						<Col md="9" lg="3">
							<Input id="address_town" {...inputProps} placeholder={this.i18n[locale].town} name="town" value={values.town} onChange={this.handleChange} pattern="[a-zA-Zα-ωΑ-Ωέύϋΰίϊΐόάήώ.\ ]+" />
						</Col>
						<Col md="3" lg="2">
							<Input id="address_zip" {...inputProps} placeholder={this.i18n[locale].zip} name="zip" value={values.zip} onChange={this.handleChange} pattern="[0-9]{5}" />
						</Col>
					</Row>
				</FormGroup>
				<Map
					value={this.state.coordinates}
					name="map"
					options={{zoom:17, position: [38.021331, 23.798630]}}
					required={true}
					readOnly={false}
					onChange={this.handlePositionChange}
					geoCoding={this.state.geoCoding}
				/>
			</FormGroup>
		);
	}
}

Address.propTypes = {
	required: PropTypes.bool,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	valid: PropTypes.string,
	readOnly: PropTypes.bool,
	locale: PropTypes.string
};

export default Address;
