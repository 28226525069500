import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	CardText,
	CardFooter,
	Button,
} from 'reactstrap';

import { ErrorPages } from 'core/model/routes';
import { Loading } from 'core/components';
import { requestData } from 'core/ducks/list';
import { postData } from 'core/ducks/update';
import { pushNotification } from 'core/ducks/notifications';
import { DynamicRoutes } from '../../model/routes';
import { buildPath } from 'core/model/lib/urlTools';
import T from 'modules/i18n';

class CreateWorkflow extends Component {

	constructor(props) {
		super(props);
		this.state = {
			modalOpen: false,
			values: {name: ''}
		};

		this.handleChange = this.handleChange.bind(this);
		this.apply = this.apply.bind(this);
		this.toggle = this.toggle.bind(this);
	}

	handleChange(event) {
		this.setState({
			values: {
				...this.state.values,
				[event.target.name]: event.target.value
			}
		});
	}

	apply(event) {
		const { dispatch } = this.props;
		const { mname } = this.props.match.params;
		event.preventDefault();
		if (this.state.modalOpen)
			this.toggle();
		const data = {'workflow': mname, 'name': this.state.values.name, 'workflow_revision': 1};
		dispatch( postData('application', data, false) );
	}

	toggle() {
		this.setState( {modalOpen: !this.state.modalOpen} );
	}

	componentDidMount() {
		const { dispatch } = this.props;
		const { mname } = this.props.match.params;
		dispatch(requestData('workflow', `workflow/mname/${mname}/revision/1`));
	}

	componentDidUpdate(prevProps) {
		if ( prevProps.list.sending && !this.props.list.sending ) {
			if (typeof this.props.list.data.mname == 'undefined')
				this.props.history.push(ErrorPages.NotFound);
		}

		if ( prevProps.update.sending && !this.props.update.sending ) {
			if (this.props.update.status === 200) {
				this.props.dispatch( pushNotification({body: 'application created', type: 'success'}) );
				if (JSON.stringify(prevProps.update.message) !== JSON.stringify(this.props.update.message)) {
					const { uuid, node } = this.props.update.message;
					let path = buildPath(DynamicRoutes.Apply, [uuid, node]);
					this.props.history.push(path);
				}
			} else {
				this.props.dispatch( pushNotification({body: 'action denied', type: 'warning'}) );
			}
		}
	}

	render() {
		const { list } = this.props;
		if (list.pending)
			return (<Loading />);

		let workflow = list.data;

		return (
			<Row>
				<Col xs="12">
					<Card>
						<CardHeader>
							<h4>{workflow.name}</h4>
						</CardHeader>
						<CardBody>
							<CardText>
								{workflow.description}
							</CardText>
						</CardBody>
						<CardFooter>
							<Button type="button" onClick={this.apply}>
								<T>apply</T>
							</Button>
						</CardFooter>
					</Card>
				</Col>

			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	list: state.list.workflow,
	update: state.update,
	i18n: state.i18n,
});

CreateWorkflow = connect(mapStateToProps)(CreateWorkflow);

export default CreateWorkflow;
