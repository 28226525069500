import React, {Component} from 'react';
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Dropdown
} from 'reactstrap';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { StaticRoutes } from 'core/model/routes';
import { logout } from 'core/ducks/profile';
import T from 'modules/i18n';

class HeaderDropdown extends Component {

	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			dropdownOpen: false
		};
	}

	toggle() {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen
		});
	}

	dropAccnt() {
		const profile = this.props.profile;
		const { dispatch } = this.props;
		return (
			<Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
				<DropdownToggle caret size="sm" className="no-outline" nav>
					<i className="fa fa-user-circle"/><span className="d-none d-lg-inline"> { profile.firstname } { profile.lastname }</span>
				</DropdownToggle>
				<DropdownMenu right>
					<DropdownItem header tag="div" className="text-center">
						<strong>
							<T>{profile.role}</T>
						</strong>
					</DropdownItem>
					<NavLink to={StaticRoutes.Profile} className="dropdown-item" onClick={this.toggle}>
						<i className="fa fa-user"/> <T>profile</T>
					</NavLink>
{/*					<NavLink to={StaticRoutes.MyWorkflows} className="dropdown-item" onClick={this.toggle}>
						<i className="fa fa-briefcase"/> <T>my workflows</T>
					</NavLink>*/}
					<DropdownItem divider/>
					<DropdownItem onClick={() => dispatch(logout())}>
						<i className="fa fa-lock"></i> <T>logout</T>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);
	}

	render() {
		return (
			this.dropAccnt()
		);
	}
}

const mapStateToProps = (state) => ({
	profile: state.profile.user,
});

HeaderDropdown = connect(mapStateToProps)(HeaderDropdown);

export default HeaderDropdown;
