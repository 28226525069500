import * as roles from 'core/model/roles';

/**
 * Static routes
 */

// Admin
const QRReader = '/qrreader';

export const StaticRoutes = {
	QRReader,
};

export const routes = {
	[QRReader]: {
		component: 'QRReader',
		role: roles.REVIEWER
	},
};

const combined = { StaticRoutes, routes};

export default combined;
