import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	Button,
	Form,
	FormFeedback,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText
} from 'reactstrap';

import { getValidation, validate } from 'core/ducks/forms';
import { postData } from 'core/ducks/update';
import { pushNotification } from 'core/ducks/notifications';
import { Loading } from 'core/components';
import T from 'modules/i18n';

class Register extends Component {

	constructor(props) {
		super(props);
		this.empty_state = {
			username: '',
			firstname: '',
			lastname: '',
			email: '',
			password: ''
		}
		this.state = {
			values: {
				...this.empty_state,
				password_repeat: ''
			},
			under_submit: false,
			submitted: false,
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.submit = this.submit.bind(this);
	}

	handleChange(event) {
		let target = event.target;
		this.setState({
			values: {
				...this.state.values,
				[target.name]: target.value
			},
			under_submit: false
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch, rules } = this.props;
		if (this.state.values.password !== this.state.values.password_repeat) {
			dispatch(pushNotification({
				body: "passwords do not match",
				type: "warning"
			}));
		} else {
			let data = Object.assign({}, this.state.values);
			delete data.password_repeat;
			this.setState({under_submit: true}, () => dispatch(validate(data, rules)));
		}
	}

	submit(data) {
		const { dispatch } = this.props;
		dispatch(postData('registration', data, false));
	}

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(getValidation('users', 'username;firstname;lastname;email;password;'));
	}

	componentWillReceiveProps(nextProps) {
		if (this.state.under_submit && nextProps.valid) {
			let data = Object.assign({}, this.state.values);
			delete data.password_repeat;
			this.submit(data);
			this.setState({under_submit: false});
		}

		if (this.props.post_pending && !nextProps.post_pending) {
			const { post_status, dispatch } = nextProps;
			if (post_status === 200) {
				dispatch(pushNotification({
					body: "successful registration",
					type: "success",
					duration: 3
				}));
				this.setState({submitted: true});
			} else if (post_status === '409') {
				dispatch(pushNotification({
					body: "username exists",
					type: "warning"
				}));
			}
		}
	}

	render() {
		if (this.props.pending)
			return <Loading />

		let message = this.props.notifications[this.props.notifications.length-1];
		const { validation_msgs, i18n } = this.props;

		return (
			<div className="app-container flex-row align-items-center">
				<Container>
					<Row className="justify-content-center">
						<Col md="6">
							<Card className="mx-4">
								<CardBody className="p-4">
									<h1><T>register</T></h1>
									<p className="text-muted"><T>create your account</T></p>
									{ message &&
										<p className={message.type}><T>{message.body}</T></p>
									}
									<Form onSubmit={this.handleSubmit}>
										<fieldset disabled={this.state.submitted}>
											<InputGroup className="mb-3">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="icon-user"></i>
													</InputGroupText>
												</InputGroupAddon>
												<Input
													valid={validation_msgs.username===''}
													type="text"
													placeholder={i18n.username}
													name="username"
													autoComplete="off"
													onChange={this.handleChange}
													required
												/>
												<FormFeedback>{validation_msgs.username}</FormFeedback>
											</InputGroup>
											<InputGroup className="mb-3">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>F</InputGroupText>
												</InputGroupAddon>
												<Input
													valid={validation_msgs.firstname===''}
													type="text"
													placeholder={i18n['given name']}
													name="firstname"
													autoComplete="given-name"
													onChange={this.handleChange}
													required
												/>
												<FormFeedback>{validation_msgs.firstname}</FormFeedback>
											</InputGroup>
											<InputGroup className="mb-3">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>L</InputGroupText>
												</InputGroupAddon>
												<Input
													valid={validation_msgs.lastname===''}
													type="text"
													placeholder={i18n['family name']}
													name="lastname"
													autoComplete="family-name"
													onChange={this.handleChange}
													required
												/>
												<FormFeedback>{validation_msgs.lastname}</FormFeedback>
											</InputGroup>
											<InputGroup className="mb-3">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>@</InputGroupText>
												</InputGroupAddon>
												<Input
													valid={validation_msgs.email===''}
													type="text"
													placeholder="Email"
													name="email"
													onChange={this.handleChange}
													required
												/>
												<FormFeedback>{validation_msgs.email}</FormFeedback>
											</InputGroup>
											<InputGroup className="mb-3">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="icon-lock"></i>
													</InputGroupText>
												</InputGroupAddon>
												<Input
													valid={validation_msgs.password===''}
													type="password"
													placeholder={i18n.password}
													name="password"
													autoComplete="off"
													onChange={this.handleChange}
													required
												/>
												<FormFeedback>{validation_msgs.password}</FormFeedback>
											</InputGroup>
											<InputGroup className="mb-4">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="icon-lock"></i>
													</InputGroupText>
												</InputGroupAddon>
												<Input
													type="password"
													placeholder={i18n['repeat password']}
													name="password_repeat"
													autoComplete="off"
													onChange={this.handleChange}
													required
												/>
											</InputGroup>
										</fieldset>
										<Row>
											<Col sm="3">
												<Button type="button" color="primary" block onClick={() => {this.props.history.goBack()}}><i className="fa fa-backward"/> <T>back</T></Button>
											</Col>
											<Col sm="9">
												<Button disabled={this.state.submitted} type="submit" color="success" block><T>create account</T></Button>
											</Col>
										</Row>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n.messages,
	pending: state.forms.pending,
	rules: state.forms.validation.rules,
	valid: state.forms.valid,
	validation_msgs: state.forms.validation_msgs,
	notifications: state.notifications.messages,
	post_pending: state.update.sending,
	post_status: state.update.status,
});

Register = connect(mapStateToProps)(Register);

export default Register;
