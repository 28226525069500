export const applicationStatus = [
	'progressing',
	'with_editor',
	'under_review',
	'author_actions',
	'under_editor_evaluation',
	'revise',
	'accepted',
	'rejected',
	'completed',
	'on_hold',
	'suspended'
];

export const applicationTypes = [
	'boolean',
	'categorical',
	'form',
	'workflow',
	'transition',
	'assignation',
	'register',
	'email'
];

export const applicationContentTypes = {
	'O': 'Operational',
	'I': 'Intermediate',
	'P': 'Product'
};

export const applicationRoles = [
	'author',
	'reviewer',
	'editor'
];

export const fieldTypes = [
	'string',
	'text',
	'attachment',
	'map',
	'radio',
	'select',
	'number',
	'date',
	'checkbox',
	'plaintext',
	'parse_pdf'
];
