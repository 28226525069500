import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
} from 'reactstrap';

import { DynamicRoutes } from '../../model/routes';
import { buildPath } from 'core/model/lib/urlTools';
import { requestData } from 'core/ducks/list';
import { Loading, Dashboard as Template, SecureContent } from 'core/components';
import * as roles from 'core/model/roles';

class WorkflowDashboard extends Component {

	componentDidMount() {
		let { dispatch, type } = this.props;
		let category = 'active';
		if (!type || (type !== 'public' && type !== 'private'))
			type = 'public';
		dispatch( requestData('workflow', `workflow/type/${type}/category/${category}`) );
	}

	render() {
	const { list } = this.props;
		if (list.pending)
			return (<Loading />);

		let items = Object.keys(list.data).map((key) => {
			let workflow = list.data[key];
			let path = buildPath(DynamicRoutes.Workflows, [key]);
			return (
				<Link key={`link_card_${key}`} to={path} className="no-decoration text-dark">
					<Card>
						<CardHeader>
							<h5>{workflow.name}</h5>
						</CardHeader>
						<CardBody className="text-white bg-info text-justify px-5">
							{workflow.description}
						</CardBody>
					</Card>
				</Link>
			);
		});

		let dashboard = (
			<Row className="mt-4">
				{ items.map((item, index) => (
					<Col key={`workflow_item_${index}`} xs="12" sm="6">
						{item}
					</Col>
				))}
			</Row>
		);

		if (this.props.items) {
			return (
				<Template>
					{ items.map((item, index) =>
						<SecureContent key={`workflow_item_${index}`} role={roles.GUEST}>
							{item}
						</SecureContent>
					)}
				</Template>
			);
		} else {
			return dashboard;
		}
	}
}

const mapStateToProps = (state) => ({
	profile: state.profile.user,
	list: state.list.workflow,
});

WorkflowDashboard = connect(mapStateToProps)(WorkflowDashboard);

export default WorkflowDashboard;
