import * as roles from 'core/model/roles';

/**
 * Static routes
 */
const AdminWorkflowsList = '/admin/workflows';
const WorkflowsList = '/workflows/list';
const WorkflowDashboard = '/workflows/register';
const MyWorkflows = '/myworkflows';
const Inactive = '/inactive';

export const StaticRoutes = {
	AdminWorkflowsList,
	WorkflowsList,
	WorkflowDashboard,
	MyWorkflows,
	Inactive,
};

/**
 * Dynamic routes
 */
const Workflows = '/workflows/register/:mname';
const Apply = '/apply/:uuid/step/:node';
const WorkflowsListStatus = AdminWorkflowsList + '/status/:status'

export const DynamicRoutes = {
	Workflows,
	Apply,
	WorkflowsListStatus,
};

export const routes = {
	[AdminWorkflowsList]: {
		name: 'workflows',
		description: 'List of Workflows (admin)',
		component: 'WorkflowsList',
		exact: true,
		role: roles.REVIEWER,
	},
	[WorkflowDashboard]: {
		name: 'workflows dashboard',
		description: 'A page to select a workflow type to initiate',
		component: 'WorkflowDashboard',
		exact: true,
	},
	[Workflows]: {
		name: 'workflows',
		component: 'CreateWorkflow'
	},
	[Apply]: {
		name: 'apply',
		description: 'Main application procedure',
		contextComponent: 'Apply',
		component: 'Apply',
	},
	[WorkflowsList]: {
		name: 'workflows',
		description: 'List of Workflows',
		component: 'WorkflowsList',
		exact: true,
	},
	[WorkflowsListStatus]: {
		name: 'workflows',
		component: 'WorkflowsList',
		role: roles.REVIEWER,
	},
	[MyWorkflows]: {
		name: 'my workflows',
		component: 'WorkflowsList',
		props: {
			personal: true
		},
	},
	[Inactive]: {
		name: 'inactive workflows',
		component: 'WorkflowsList',
		props: {
			inactive: true
		},
	}
};

const combined = {StaticRoutes, DynamicRoutes, routes};

export default combined;
