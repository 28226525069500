import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'settings',
		url: StaticRoutes.Settings,
		icon: 'icon-wrench',
		role: roles.ADMIN,
		children: [
			{
				name: 'messages',
				url: StaticRoutes.SettingsMessages
			},
			{
				name: 'register fields',
				url: StaticRoutes.RegisterFields
			},
			{
				name: 'pdf templates',
				url: StaticRoutes.PdfTemplates
			}
		]
	},
	{
		name: 'edit',
		url: StaticRoutes.Edit,
		icon: 'icon-pencil',
		role: roles.ADMIN,
		children: [
			{
				name: 'forms',
				url: StaticRoutes.EditForms
			},
			{
				name: 'draw workflow',
				url: StaticRoutes.EditWorkflows
			}
		]
	},
];

export default menu;
