import React, { Component } from 'react';
import { FormattedDate } from 'react-intl';
import {
	Modal,
	ModalHeader,
	ModalBody,
	Row,
	Col
} from 'reactstrap';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';

import Map from './map';

import 'react-image-lightbox/style.css';
import '../scss/style.scss';

class View extends Component {

	constructor(props) {
		super(props);
		this.state = { isLBOpen: false };
	}

	render() {

		let { toggle, title, data, details, location } = this.props;
		let photo;
		let values = [];

		location = [location.lat, location.lon];

		data.forEach(entry => {
			if (entry.type === 'attachment') {
				photo = entry.value;
			} else if (entry.type === 'address') {
				values.push({
					...entry,
					value: entry.value.replace(/\+/g, ' ')
				});
			} else {
				values.push(entry);
			}
		});

		return (
			<Modal isOpen={true} className="modal-lg">
				<ModalHeader toggle={toggle}>{title}</ModalHeader>
				<ModalBody>
					<Row>
						<Col>
							<Map position={location}/>
						</Col>
					</Row>
					<Row>
						<Col>
							<img src={photo} style={{width: 100 + '%', height: 'auto'}} onClick={() => {this.setState({isLBOpen: true})}} alt=""/>
						</Col>
						<Col>
							{ values.map(elem =>
								<Row key={`row_${elem.key}`}>
									<Col className="bg-light font-weight-bold with-border-bottom" sm="12" md="4">
										{elem.key}
									</Col>
									<Col className="with-border-bottom fixed-height py-1" sm="12" md="8">{elem.value}</Col>
								</Row>
							)}
						</Col>
					</Row>
				</ModalBody>
				{ (this.state.isLBOpen) &&
					<Lightbox
						mainSrc={photo}
						onCloseRequest={() => this.setState({isLBOpen: false})}
						onMovePrevRequest={() => {}}
						onMoveNextRequest={() => {}}
					/>
				}
			</Modal>
		);
	}
}

View.propTypes = {
	toggle: PropTypes.func.isRequired,
	title: PropTypes.string,
	data: PropTypes.array.isRequired,
	location: PropTypes.object.isRequired,
	details: PropTypes.object,
};

export default View;
