import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Row, Col, Card, CardHeader, CardBody,
	Form, FormGroup, FormText, FormFeedback, Button, Input, Label
} from 'reactstrap';

import { Map, Radio, CheckBox, PlainText, GeneralInput, Address } from 'input-fields';
import { File } from 'file-upload';
import { ParsePDF } from 'parse-pdf';
import { Table, Title, Tbody, Tr } from 'table';
import { ErrorPages } from 'core/model/routes';
import { DynamicRoutes } from '../../model/routes';
import { buildPath } from 'core/model/lib/urlTools';
import { Loading, Error } from 'core/components';
import { requestData } from 'core/ducks/list';
import { postData, updateData } from 'core/ducks/update';
import { createBreadcrumb } from 'core/ducks/ui/breadcrumb';
import { validate } from 'core/ducks/forms';
import { countNotifications } from 'messages/ducks/messages';
import T from 'modules/i18n';
import { getLocale } from 'modules/i18n';

class Apply extends Component {

	constructor(props) {
		super(props);
		this.state = {
			values: {},
			rules: {},
			under_submit: false,
			ready: false
		};

		this.initiateValues = this.initiateValues.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleRowSelect = this.handleRowSelect.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleBooleanSubmit = this.handleBooleanSubmit.bind(this);
		this.createBreadcrumb = this.createBreadcrumb.bind(this);
	}

	initiateValues() {
		const { data, parsedForm, parsedValues } = this.props;
		const { node } = this.props.match.params;
		this.node = node==='current' ? data.application.current_node : node;
		let values = {};
		let rules = {};
		if (data.node.type === 'form' || data.node.type === 'register') {
			data.content.forEach((field) => {
				if (parsedForm === data.node.content) {
					values[field.name] = parsedValues[field.name];
				} else {
					values[field.name] = field.value ? field.value : '';
				}
				rules[field.name] = {
					type: field.field_type,
					min_size: field.min_size,
					max_size: field.max_size,
					validation: field.validation,
					message: field.validation_msg
				};
			});
		} else {
			values = { [this.node]: data.content.value };
		}
		this.initialValues = values;
		this.setState({values, rules, ready: true});
		this.createBreadcrumb();
	}

	createBreadcrumb() {
		const { history } = this.props.data;
		const { uuid } = this.props.match.params;
		const items = {};
		Object.keys(history).forEach(key => {
			let url = buildPath(DynamicRoutes.Apply, [uuid, key]);
			items[key] = {name: history[key], url, active: key === this.props.data.node.mname}
		});
		this.props.dispatch( createBreadcrumb(items) );
	}

	handleChange(event) {
		let target = event.target;
		this.setState({
			values: {
				...this.state.values,
				[target.name]: target.value
			}
		});
	}

	handleRowSelect(uuid) {
		this.setState({
			values: {
				...this.state.values,
				[this.node]: uuid
			}
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch } = this.props;
		dispatch( validate(this.state.values, this.state.rules) );
		this.setState({under_submit: true});
	}

	handleBooleanSubmit(event) {
		event.preventDefault();
		const { dispatch } = this.props;
		let { uuid } = this.props.match.params;
		dispatch(
			postData(
				`apply/application/${uuid}/node/${this.node}`,
				{[this.node]: event.currentTarget.value}
			)
		);
	}

	componentDidMount() {
		const { dispatch } = this.props;
		const { uuid } = this.props.match.params;
		dispatch( requestData('application', `application/uuid/${uuid}`) );
	}

	componentDidUpdate(prevProps) {
		const { dispatch, list, history, data, response, http_status } = this.props;
		const { uuid, node } = this.props.match.params;
		// if (prevProps.http_status != '404' && http_status == '404')
		// 	history.push(ErrorPages.NotFound);
		// Detect URL change
		if ( prevProps.match.params.node !== node ) {
			this.setState({ready: false});
			dispatch( requestData('apply', `apply/application/${list.data.uuid}/node/${node}`) ).then(() => {
				this.initiateValues();
			});
		}
		// Fetch form
		if ( (prevProps.list.pending && !list.pending) || (prevProps.list.refreshing && !list.refreshing) ) {
			if (typeof list.data.uuid === 'undefined') {
				history.push(ErrorPages.NotFound);
			} else {
				dispatch( requestData('apply', `apply/application/${list.data.uuid}/node/${node}`) ).then(() => {
					this.initiateValues();
				});
			}
		}
		// Load subworkflow if necessary
		if ( prevProps.http_status === '' && http_status === 200) {
			if ( data.node.type === 'workflow' )
				dispatch( requestData('subworkflow', `application/limit/50/fq/workflow:${data.node.workflow_content}`) );
		}
		// Submit form data
		if ( this.state.under_submit ) {
			if (this.props.valid) {
				let content_path = data.node.content ? `/content/${data.node.content}` : '';
				let path = `apply/application/${uuid}/node/${this.node}${content_path}`;
				if ( this.node === data.application.current_node ) {
					dispatch( postData(path, this.state.values) );
				} else {
					let values = Object.keys(this.state.values).filter(
						name => this.state.values[name] !== this.initialValues[name]
					).reduce((obj, name) => ({...obj, [name]: this.state.values[name]}), {});
					if (Object.keys(values).length > 0)
						dispatch( updateData(path, values) );
				}
			}
			this.setState({under_submit: false});
		}
		// Proceed to next step
		if (JSON.stringify(prevProps.response) !== JSON.stringify(response) && response.current_node) {
			dispatch(countNotifications());
			let path = buildPath(DynamicRoutes.Apply, [uuid, response.current_node]);
			history.push(path);
			this.setState({ready: false});
			dispatch( requestData('application', `application/uuid/${list.data.uuid}`) );
		}
	}

	componentWillUnmount() {
		this.props.dispatch( createBreadcrumb({}) );
	}

	render() {
		const { parent_list, list, data, validation_msgs, http_status, http_msg } = this.props;

		if (http_status !== 200 && http_status !== '')
			return <Error status={http_status} errorMsg={http_msg} />;
		if (!data.node || !this.state.ready)
			return (<Loading />);

		let content;
		const read_only = this.props.readOnly ? true : (data.application.current_node !== data.node.mname && data.node.content_type !== 'I');

		const locale = this.props.dispatch(getLocale());

		if (data.node.content_type === 'O')
			return (
				<Row>
					<Col>
						<Card>
							<CardHeader>
								<h5>{data.application.name}</h5>
								{data.node.label}
							</CardHeader>
							<CardBody dangerouslySetInnerHTML={{__html: data.node.content_text}} />
						</Card>
					</Col>
				</Row>
			);

		switch (data.node.type) {
			case 'form':
			case 'register':
				const types = {string: 'text', text: 'textarea', attachment: 'file', number: 'number', date: 'date', select: 'select', radio: 'radio', checkbox: 'checkbox', map: 'map', plaintext: 'plaintext', parse_pdf: 'parse_pdf', address: 'address'};
				content = data.content.map((field) => {
					if (typeof types[field.field_type] !== 'undefined') {
						const type = types[field.field_type];
						const field_options = (field.options && JSON.parse(field.options)) ? JSON.parse(field.options) : undefined;
						let input_field;
						switch (type) {
							case 'radio':
								input_field = (
									<Radio
										value={this.state.values[field.name]}
										required={field.is_required}
										name={field.name}
										onChange={this.handleChange}
										text={field_options}
										readOnly={field.final}
									/>
								);
								break;

							case 'checkbox':
								input_field = (
									<CheckBox
										value={this.state.values[field.name]}
										required={field.is_required}
										name={field.name}
										onChange={this.handleChange}
										options={field_options}
										readOnly={field.final}
									/>
								);
								break;

							case 'map':
								input_field = (
									<Map
										value={this.state.values[field.name]}
										name={field.name}
										options={field_options}
										required={field.is_required}
										readOnly={field.final || false}
										onChange={this.handleChange}
									/>
								);
								break;

							case 'plaintext':
								input_field = (
									<PlainText
										name={field.name}
										label={field.label}
										onChange={this.handleChange}
									/>
								);
								break;

							case 'file':
								input_field = (
									<File
										name={field.name}
										value={this.state.values[field.name]}
										required={field.is_required}
										locale={locale}
										onChange={this.handleChange}
										readOnly={field.final}
										filetype={field_options ? field_options.acceptedFileTypes : undefined}
										maxFileSize={field_options ? field_options.maxFileSize : undefined}
									/>
								);
								break;

							case 'parse_pdf':
								input_field = (
									<ParsePDF
										name={field.name}
										locale={locale}
										form={field_options.form}
										application={this.props.match.params.uuid}
										filetype="application/pdf"
										maxFileSize={field_options.maxFileSize || undefined}
									/>
								);
								break;

							case 'address':
								input_field = (
									<Address
										name={field.name}
										required={field.is_required}
										value={this.state.values[field.name]}
										onChange={this.handleChange}
										readOnly={field.final}
										locale={locale}
									/>
								);
								break;

							default:
								input_field = (
									<GeneralInput
										id={`input_${field.name}`}
										type={type}
										required={field.is_required}
										maxLength={field.max_size>0 ? field.max_size : undefined}
										name={field.name}
										value={this.state.values[field.name]}
										onChange={this.handleChange}
										pattern={field.validation}
										readOnly={field.final}
										options={field_options ? field_options : undefined}
									/>
								);
						}
						return (
							field.field_type !== 'plaintext' ? (
								<Row key={`${field.form}_${field.name}`}>
									<Col>
										<FormGroup>
											<Label htmlFor={`input_${field.name}`} className="application-label">
												{field.label}{field.is_required ? ' *' : ''}
											</Label>
											{ input_field }
											<FormFeedback><T>{validation_msgs[field.name]}</T></FormFeedback>
											<FormText>{field.description}</FormText>
										</FormGroup>
									</Col>
								</Row>
							)
							: (
								<Row key={field.name}>
									<Col>
										{ input_field }
									</Col>
								</Row>
							)
						);
					} else {
						return null
					}
				});
				content = (
					<Form onSubmit={this.handleSubmit} encType="multipart/form-data" >
						<fieldset disabled={read_only} >
							{content}
							{ !read_only &&
								<Row>
									<Col className="text-right">
										<Button type="submit">
											<T>{data.node.content_type==='I'
												? (this.node===data.application.current_node ? 'continue' : 'update')
												: (this.node===data.application.current_node ? 'submit' : 'update')
											}</T>
										</Button>
									</Col>
								</Row>
							}
						</fieldset>
					</Form>
				);
				break;

			case 'boolean':
				content = (
					<React.Fragment>
						<Row>
							<Col>
								<FormGroup>
									<Input plaintext >{data.node.content_text}</Input>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col className="text-right">
								<Button
									className={((read_only && !data.content.value) ? 'd-none' : undefined)}
									disabled={read_only}
									color="primary"
									size="lg"
									value={true}
									onClick={this.handleBooleanSubmit}
								>
									<T>yes</T>
								</Button>
							</Col>
							<Col>
								<Button
									className={((read_only && data.content.value) ? 'd-none' : undefined)}
									disabled={read_only}
									size="lg"
									value={false}
									onClick={this.handleBooleanSubmit}
								>
									<T>no</T>
								</Button>
							</Col>
						</Row>
					</React.Fragment>
				);
				break;

			case 'categorical':
				let options = JSON.parse(data.node.options);
				content = (
					<Form onSubmit={this.handleSubmit}>
						<fieldset disabled={read_only} >
							<Row>
								<Col>
									<FormGroup tag="fieldset">
										<Input plaintext defaultValue={data.node.content_text} />
										{ Object.keys(options).map((value) => {
											let text = options[value];
											return (
												<FormGroup key={`form_group_${value}`} className="mb-1" check >
													<Label check >
														<Input
															type="radio"
															name={this.node}
															checked={this.state.values[this.node]===value}
															value={value}
															onChange={this.handleChange}
														/>
														{` ${text}`}
													</Label>
												</FormGroup>
											);
										})}
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col className="text-right">
									<Button type="submit"><T>submit</T></Button>
								</Col>
							</Row>
						</fieldset>
					</Form>
				);
				break;

			case 'transition':
			case 'assignation':
				content = (
					<React.Fragment>
						<Row>
							<Col>
								<Table>
									<Title>{data.node.content_text}</Title>
									<Tbody>
										<Tr
											data={data.content.options}
											onRowClick={read_only ? undefined : this.handleRowSelect}
											selectedRow={this.state.values[this.node]}
										/>
									</Tbody>
								</Table>
							</Col>
						</Row>
						<Row>
							<Col className="text-right">
								<Button disabled={read_only} onClick={this.handleSubmit}>
									<T>submit</T>
								</Button>
							</Col>
						</Row>
					</React.Fragment>
				);
				break;

			case 'workflow':
				content = (
					<React.Fragment>
						<Row>
							<Col>
								<FormGroup>
									<Label htmlFor="select_subworkflow" className="application-label">{data.node.content_text}</Label>
									<Input
										id="select_subworkflow"
										type="select"
										name={this.node}
										value={this.state.values[this.node]}
										onChange={this.handleChange}
									>
										<option value="">---</option>
										{ (parent_list.subworkflow && parent_list.subworkflow.data && parent_list.subworkflow.data.values)
											&& Object.keys(parent_list.subworkflow.data.values).map( (value) => (
												<option key={`option_${value}`} value={value}>
													{parent_list.subworkflow.data.values[value].name}
												</option>
											))
										}
									</Input>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col className="text-right">
								<Button disabled={read_only} onClick={this.handleSubmit}>
									<T>submit</T>
								</Button>
							</Col>
						</Row>
					</React.Fragment>
				);
				break;

			default:
				content = null;
		}

		return (
			<Row>
				<Col>
					<Card>
						<CardHeader>
							<h5>{data.application.name}</h5>
							{data.node.label}
						</CardHeader>
						<CardBody className={list.refreshing ? 'semi-transparent' : undefined}>
							{content}
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	parent_list: state.list,
	list: state.list.application,
	data: state.list.apply.data,
	http_status: state.list.apply.status,
	http_msg: state.list.apply.errorMsg,
	readOnly: state.list.apply.data.readOnly,
	valid: state.forms.valid,
	validation_msgs: state.forms.validation_msgs,
	response: state.update.message,
	parsedForm: state.parsePdf.form,
	parsedValues: state.parsePdf.values,
});

Apply = connect(mapStateToProps)(Apply);

export default Apply;
