import * as roles from 'core/model/roles';

/**
 * Static routes
 */

// Admin
const Settings = '/admin/settings';
const SettingsMessages = '/admin/settings/messages';
const PdfTemplates = '/admin/settings/pdf_templates';
const RegisterFields = '/admin/settings/register_fields';
const Edit = '/admin/edit';
const EditForms = '/admin/edit/forms';
const EditWorkflows = '/admin/edit/draw';

export const StaticRoutes = {
	Settings,
	SettingsMessages,
	PdfTemplates,
	RegisterFields,
	Edit,
	EditForms,
	EditWorkflows
};

/**
 * Dynamic routes
 */
const EditFormsDynamic = EditForms + '/form/:form';
const EditWorkflowsDynamic = EditWorkflows + '/workflow/:workflow';
const PdfTemplateDynamic = PdfTemplates + '/workflow/:workflow';

export const DynamicRoutes = {
	EditWorkflows: EditWorkflowsDynamic,
	EditForms: EditFormsDynamic,
	PdfTemplates: PdfTemplateDynamic,
};

export const routes = {
	[Settings]: {
		name: 'settings',
		to: SettingsMessages,
		exact: true,
	},
	[SettingsMessages]: {
		name: 'messages',
		component: 'SettingsMessages',
		role: roles.ADMIN,
	},
	[PdfTemplates]: {
		name: 'pdf templates',
		description: 'Pdf Templates settings page',
		title: 'links.settings.pdf_templates',
		defaultTitle: 'Pdf Templates',
		contextComponent: 'SelectWorkflow',
		component: 'PdfTemplates',
		role: roles.ADMIN,
	},
	[PdfTemplateDynamic]: {
		name: 'pdf templates',
		description: 'Admin Pdf Template settings',
		contextComponent: 'SelectWorkflow',
		component: 'PdfTemplates',
		role: roles.ADMIN,
	},
	[RegisterFields]: {
		name: 'register fields',
		component: 'RegisterFields',
		role: roles.ADMIN,
	},
	[Edit]: {
		name: 'edit',
		description: 'Admin Edit Menu',
		title: 'links.edit',
		defaultTitle: 'Admin Edit Menu',
		to: EditForms,
		exact: true,
	},
	[EditForms]: {
		name: 'forms',
		description: 'Admin Edit Forms',
		title: 'links.edit.forms',
		defaultTitle: 'Admin Edit Forms',
		contextComponent: 'EditForms',
		component: 'EditForms',
		role: roles.ADMIN,
		exact: true,
	},
	[EditFormsDynamic]: {
		name: 'forms',
		description: 'Admin Edit Forms',
		contextComponent: 'EditForms',
		component: 'EditForms',
		role: roles.ADMIN,
	},
	[EditWorkflows]: {
		name: 'workflows',
		description: 'Admin Draw Workflows',
		title: 'links.edit.draw',
		defaultTitle: 'Admin Draw Workflows',
		contextComponent: 'EditWorkflows',
		component: 'EditWorkflows',
		role: roles.ADMIN,
		exact: true,
	},
	[EditWorkflowsDynamic]: {
		name: 'workflows',
		description: 'Admin Draw Workflows',
		contextComponent: 'EditWorkflows',
		component: 'EditWorkflows',
		role: roles.ADMIN,
		exact: true,
	},
};

const combined = {StaticRoutes, DynamicRoutes, routes};

export default combined;
