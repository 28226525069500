import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import Tree from 'react-ui-tree';

import * as roles from 'core/model/roles';
import { SecureContent } from 'core/components';
import T from 'modules/i18n';
import { getData } from 'core/ducks/update';
import { requestData } from 'core/ducks/list';

class FileTab extends Component {

	constructor(props) {
		super(props);
		this.state = {tree: null};

		this.handlePrint = this.handlePrint.bind(this);
		this.renderNode = this.renderNode.bind(this);
	}

	componentDidMount() {
		this.props.dispatch( requestData('files', `applicationFiles/uuid/${this.props.uuid}`) );
	}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps.files) !== JSON.stringify(this.props.files))
			this.setState({tree: this.props.files});
	}

	handlePrint() {
		const { dispatch } = this.props;
		dispatch( getData(`createPdf/uuid/${this.props.uuid}`) );
	}

	renderNode(node) {
		let rendered_node;
		if (node.path) {
			rendered_node = (
				<Link to={node.path} target="_blank">
					{node.module}
				</Link>
			);
		} else {
			rendered_node = (<span>{node.module}</span>);
		}
		return rendered_node;
	}

	render() {
		const { uuid, files_pending } = this.props;
		return (
			<React.Fragment>
				<Row>
					<Col className="text-center py-2 border-top border-bottom mb-2">
						<Link to={`/application/pdf/${uuid}.pdf`} target="_blank" role="button"><i className="fa fa-print"/> <T>print</T></Link>
					</Col>
				</Row>
				<SecureContent role={roles.REVIEWER}>
					{ (!files_pending && this.state.tree) ?
						<Tree
							paddingLeft={10}
							tree={this.state.tree}
							onChange={() => {}}
							renderNode={this.renderNode}
							isNodeCollapsed={true}
						/>
						:
						null
					}
				</SecureContent>
			</React.Fragment>
		);
	}

}

const mapStateToProps = (state) => ({
	files: state.list.files.data,
	files_pending: state.list.files.pending,
});

FileTab = connect(mapStateToProps)(FileTab);

export default FileTab;
