const Login = '/login';
const Register = '/register';
const PassRecovery = '/password_recovery';

export const StaticRoutes = {
	Login, Register, PassRecovery
};

export const routes = {
	[Login]: {
		name: 'login',
		component: 'Login'
	},
	[Register]: {
		name: 'registration',
		component: 'Register'
	},
	[PassRecovery]: {
		name: 'password recovery',
		component: 'PassRecovery'
	}
}

const combined = {StaticRoutes, routes};

export default combined;
