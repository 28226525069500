import React, { Component } from 'react';
import { connect } from 'react-redux';

class GroupReport extends Component {

	constructor(props) {
		super(props);
		this.frameRef = React.createRef();
	}

	render() {

		return (
			<div className="grouping">
				<embed title="Report" className="w-100 h-100" src="http://lighting.gr/api/createReport/date/2019-5-22" type="application/pdf" ref={this.frameRef}/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({});

GroupReport = connect(mapStateToProps)(GroupReport);

export default GroupReport;
