import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'grouping',
		url: StaticRoutes.Groups,
		icon: 'fa fa-group',
		role: roles.EDITOR,
		children: [
			{
				name: 'definition',
				url: StaticRoutes.GroupsDefinition
			},
			{
				name: 'assignation',
				url: StaticRoutes.GroupsAssign
			},
			{
				name: 'report',
				url: StaticRoutes.GroupsReport
			}
		]
	},
];

export default menu;
