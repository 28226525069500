import * as roles from '../model/roles';

/**
 * Pages
 */
// const Login = '/login';
// const Register = '/register';
const Admin = '/admin';
// const PassRecovery = '/password_recovery';

export const Pages = {
	Admin
};

/**
 * Static routes
 */

// Admin
const AdminDashboard = '/admin/dashboard';
// const AdminWorkflowsList = '/admin/workflows';
// const Settings = '/admin/settings';
// const SettingsMessages = '/admin/settings/messages';
// const Templates = '/admin/settings/templates';
// const PdfTemplates = '/admin/settings/pdf_templates';
// const RegisterFields = '/admin/settings/register_fields';
// const SMTP = '/admin/settings/smtp';
// const Users = '/admin/users';
// const UsersList = '/admin/users/list';
// const UsersAdd = '/admin/users/add';
// const Edit = '/admin/edit';
// const EditForms = '/admin/edit/forms';
// const EditWorkflows = '/admin/edit/draw';
// const AdminSearch = '/admin/search';
// User
const Dashboard = '/dashboard';
const Profile = '/profile';
// const Messages = '/messages';
// const WorkflowsList = '/workflows/list';
// const WorkflowDashboard = '/workflows/register';
// const WorkflowsMap = '/workflows/map';
// const Organizations = '/institutions';
// const OrganizationsList = '/institutions/list';
// const OrganizationsAdd = '/institutions/add';
// const MyWorkflows = '/myworkflows';
// const Inactive = '/inactive';
// const QRReader = '/qrreader';
const Root = '/';

export const StaticRoutes = {
	AdminDashboard,
	Dashboard,
	Profile,
	// Messages,
	// Settings,
	// SettingsMessages,
	// Templates,
	// PdfTemplates,
	// SMTP,
	// Users,
	// UsersList,
	// UsersAdd,
	// WorkflowsList,
	// AdminWorkflowsList,
	// WorkflowDashboard,
	// WorkflowsMap,
	// Edit,
	// EditForms,
	// EditWorkflows,
	// Organizations,
	// OrganizationsList,
	// OrganizationsAdd,
	// AdminSearch,
	// MyWorkflows,
	// RegisterFields,
	// Inactive,
	// QRReader,
	Root
};

/**
 * Dynamic routes
 */
// const Workflows = '/workflows/register/:mname';
// const Apply = '/apply/:uuid/step/:node';
// const UsersRole =  UsersList + '/role/:role';
// const UsersStatus = UsersList + '/status/:status';
// const UsersRoleStatus =  UsersList + '/role/:role/status/:status';
// const WorkflowsListStatus = AdminWorkflowsList + '/status/:status'
// const EditFormsDynamic = EditForms + '/form/:form';
// const EditWorkflowsDynamic = EditWorkflows + '/workflow/:workflow';
// const PdfTemplateDynamic = PdfTemplates + '/workflow/:workflow';
// const TemplatesDynamic = Templates + '/template/:template';

export const DynamicRoutes = {
// 	Workflows,
// 	Apply,
	// UsersRole,
	// UsersStatus,
// 	UsersRoleStatus,
	// WorkflowsListStatus,
// 	EditWorkflows: EditWorkflowsDynamic,
// 	EditForms: EditFormsDynamic,
// 	PdfTemplates: PdfTemplateDynamic,
// 	Templates: TemplatesDynamic,
};

/**
 * Routes for error pages
 */

const Forbidden = '/error/403';
const NotFound = '/error/404';

export const ErrorPages = {
	Forbidden,
	NotFound,
};

export const routes = {
	[Root]: {
		name: 'home',
		component: 'Front',
		exact: true
	},
	[AdminDashboard]: {
		name: 'dashboard',
		component: 'Dashboard',
		role: roles.REVIEWER
	},
	[Dashboard]: {
		name: 'dashboard',
		component: 'Dashboard'

	},
	[Profile]: {
		name: 'profile',
		component: 'Profile',
		role: roles.AUTHORIZED
	},
	// [Messages]: {
	// 	description: 'Messages',
	// 	title: 'links.messages',
	// 	defaultTitle: 'Messages'
	// },
	// [Settings]: {
	// 	description: 'Settings (null) page',
	// 	title: 'links.settings',
	// 	defaultTitle: 'Settings'
	// },
	// [SettingsMessages]: {
	// 	description: 'Settings for messages',
	// 	title: 'links.settings.messages',
	// 	defaultTitle: 'Settings for messages'
	// },
	// [Templates]: {
	// 	description: 'Templates settings page',
	// 	title: 'links.settings.templates',
	// 	defaultTitle: 'Templates',
	// 	contextComponent: SidebarSelectTemplate,
	// },
	// [SMTP]: {
	// 	description: 'SMTP settings page',
	// 	title: 'links.smtp',
	// 	defaultTitle: 'SMTP settings page'
	// },
	// [Users]: {
	// 	description: 'Users general page',
	// 	title: 'links.users',
	// 	defaultTitle: 'Users'
	// },
	// [UsersList]: {
	// 	description: 'Users list',
	// 	title: 'links.users.list',
	// 	defaultTitle: 'Users List'
	// },
	// [UsersAdd]: {
	// 	description: 'Add users',
	// 	title: 'links.users.add',
	// 	defaultTitle: 'Add a new user'
	// },
	// // Pages
	// [Login]: {
	// 	description: 'Login page',
	// 	title: 'links.login',
	// 	defaultTitle: 'Login'
	// },
	// [Register]: {
	// 	description: 'Register page',
	// 	title: 'links.register',
	// 	defaultTitle: 'Register'
	// },
	// [Admin]: {
	// 	description: 'Admin page for redirection',
	// 	title: 'links.admin',
	// 	defaultTitle: 'Administration'
	// },
	// [PassRecovery]: {
	// 	description: 'Password recovery page',
	// 	title: 'links.password_recovery',
	// 	defaultTitle: 'PassRecovery'
	// },
	// // Error Pages
	// [Forbidden]: {
	// 	description: 'Forbidden',
	// },
	// [NotFound]: {
	// 	description: 'Not Found',
	// },
	// Dynamic Pages
	// [TemplatesDynamic]: {
	// 	description: 'Admin Template settings',
	// 	contextComponent: SidebarSelectTemplate,
	// },
};

export default routes;
