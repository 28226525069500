import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Prompting the user before navigating away
 * from a screen with a component.
 */
class Prompt extends Component {

	constructor(props) {
		super(props);
		this.state = {nextLocation: null};
		this.onConfirm = this.onConfirm.bind(this);
	}

	enable() {
		if (this.unblock) {
			this.unblock();
		}
		this.unblock = this.props.history.block((nextLocation) => {
			this.props.action(this.onConfirm);
			if (this.props.when) {
				this.setState({
					nextLocation: nextLocation.pathname
				});
			}
			return !this.props.when;
		});
	}

	disable() {
		if (this.unblock) {
			this.unblock();
			this.unblock = null;
		}
	}

	onConfirm() {
		this.disable();
		this.props.history.push(this.state.nextLocation);
	}

	componentDidMount() {
		if (this.props.when)
			this.enable();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.when) {
			if (!this.props.when || this.props.message !== nextProps.message) {
				this.enable(nextProps.message);
			}
		} else {
			this.disable();
		}
	}

	componentWillUnmount() {
		this.disable();
	}

	render() {
		return null;
	}
}

Prompt.propTypes = {
	when: PropTypes.bool.isRequired,
	action: PropTypes.func.isRequired
}

export default withRouter(Prompt);
