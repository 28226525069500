import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'QR Reader',
		url: StaticRoutes.QRReader,
		icon: 'fa fa-qrcode',
		role: roles.REVIEWER,
	}
];

export default menu;
