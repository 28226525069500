import { Api } from '../../../core/api';


// Actions

const NUMBER_REQUEST = 'api/notifications/NUMBER_REQUEST';
const GET_NUMBER = 'api/notifications/GET_NUMBER';
const MESSAGES_REQUEST = 'api/notifications/MESSAGES_REQUEST';
const GOT_MESSAGES = 'api/notifications/GOT_MESSAGES';
const REMOVE_MESSAGES = 'notifications/REMOVE_MESSAGES';
const MARKED_READ = 'api/notifications/MARKED_READ';


// Initial state

const initialState = {
	count: 0,
	list: {},
	pending: false,
	pending_list: true,
	status: ''
}

// Reducer

export default (state=initialState, action) => {
	switch (action.type) {
		case NUMBER_REQUEST:
			return {
				...state,
				pending: action.pending,
			};

		case MESSAGES_REQUEST:
			return {
				...state,
				pending_list: action.pending,
			};

		case GET_NUMBER:
			return {
				...state,
				pending: action.pending,
				status: action.status,
				count: action.count,
			};

		case GOT_MESSAGES:
		case REMOVE_MESSAGES:
			return {
				...state,
				pending_list: action.pending,
				status: action.status,
				list: action.list,
			}

		case MARKED_READ:
		default:
			return state;
	}
}


// Action creators

const requestNumber = () => ({
	type: NUMBER_REQUEST,
	pending: true,
});

const getNumber = (count, status) => ({
	type: GET_NUMBER,
	pending: false,
	status,
	count,
});

const requestMessages = () => ({
	type: MESSAGES_REQUEST,
	pending: true,
});

const gotMessages = (list, status) => ({
	type: GOT_MESSAGES,
	pending: false,
	status,
	list
});

const markedRead = () => ({
	type: MARKED_READ,
})

export const removeMessages = () => ({
	type: REMOVE_MESSAGES,
	pending: true,
	status: '',
	list: []
});


// Thunk action creators

export const countNotifications = () => (dispatch) => {
	let a = new Api('notifications/count/unread');
	dispatch(requestNumber());
	let status;
	let promise = a.Get();
	promise.then(response => {
		status = response.status;
		return response.json();
	}).then(json => {
		dispatch(getNumber(json, status));
	});
	return promise;
}

export const getMessages = (page=null) => (dispatch) => {
	let a = !page ? new Api('notifications') : new Api(`notifications/page/${page}`);
	dispatch( requestMessages() );
	let status;
	let promise = a.Get();
	promise.then(response => {
		status = response.status;
		return response.json();
	}).then(json => {
		dispatch(gotMessages(json, status));
	});
	return promise;
}

export const markRead = (ids) => (dispatch) => {
	if (ids.length === 0)
		return;
	let a = new Api('notifications');
	a.Post(ids)
		.then(response => response.json())
		.then(json => {
			dispatch(countNotifications());
			dispatch( markedRead() );
		});
}
