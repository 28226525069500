import React, { Component } from 'react';
import { Map as LeafletMap, Marker, TileLayer, WMSTileLayer } from 'react-leaflet';
import Control from 'react-leaflet-control';
import L from 'leaflet';
import proj4 from 'proj4';
import sat from 'workflows-map/img/sat.png';

import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';

delete L.Icon.Default.prototype._getIconUrl;

class Map extends Component {

	constructor(props) {
		super(props);
		proj4.defs('EPSG:2100', "+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=-199.87,74.79,246.62,0,0,0,0 +units=m +no_defs");
		this.state = {
			showAerial: false,
		};
	}

	render() {
		let { position } = this.props;
		return (
			<LeafletMap
				style={{width: 100 + '%', height: 30 + 'vh'}}
				center={position}
				zoom={17}
				maxZoom={20}
			>
				<TileLayer
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
				/>
				{ this.state.showAerial &&
					<WMSTileLayer
						layers="KTBASEMAP"
						url="http://gis.ktimanet.gr/wms/wmsopen/wmsserver.aspx?"
						opacity={0.4}
					/>
				}
				<Control position="topright">
					<button
						style={{
							backgroundColor: 'rgba(0,0,0,0)',
							borderStyle: this.state.showAerial ? 'inset' : 'outset'
						}}
						onClick={() => {this.setState({showAerial: !this.state.showAerial})}}
					>
						<img src={sat} alt="Aerial" />
					</button>
				</Control>
				{ this.props.position &&
					<Marker
						position={position}
						style={{width: 5 + 'px', height: 5 + 'px'}}
					/>
				}
			</LeafletMap>
		);
	}
}

export default Map;
