import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardTitle, CardBody, Row, Col } from 'reactstrap';
import { FormattedDate } from 'react-intl';

class Job extends Component {

	handleViewClick = () => {
		if (this.props.view && typeof this.props.view == 'function' && this.props.id)
			this.props.view(this.props.id);
	}

	render() {
		const { name, comment, identifier, created, lastAction, view, ...other } = this.props;
		return (
			<Card style={{border: '1px solid darkgreen', backgroundColor: 'rgba(55,70,55,0.1)'}} {...other}>
				<CardBody>
					<CardTitle>
						<Row>
							<Col className="py-0 font-weight-bold">
								{name}
							</Col>
							<Col className="py-0 text-right">
								<span className="small">{identifier}</span>
								<i className="fa fa-eye d-inline-block" role="button" onClick={this.handleViewClick} />
							</Col>
						</Row>
					</CardTitle>
					<Row>
						<Col className="py-0">
							<p className="text-justify mx-2">{comment}</p>
						</Col>
					</Row>
					<Row>
						<Col className="py-0">
							<i className="fa fa-plus d-inline-block" />{' '}
							<FormattedDate value={created} />
						</Col>
						<Col className="py-0">
							<i className="fa fa-pencil d-inline-block" />{' '}
							<FormattedDate value={lastAction} />
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	}
}

Job.propTypes = {
	name: PropTypes.string.isRequired,
	comment: PropTypes.string.isRequired,
	id: PropTypes.string,
	identifier: PropTypes.string,
	created: PropTypes.string.isRequired,
	lastAction: PropTypes.string,
	view: PropTypes.func,
}

export default Job;