/**
 * Static routes
 */

// Admin
const WorkflowsMap = '/workflows/map';

export const StaticRoutes = {
	WorkflowsMap,
};

export const routes = {
	[WorkflowsMap]: {
		name: 'map',
		description: 'A map with the workflows',
		title: 'links.workflows.map',
		defaultTitle: 'Workflows map',
		component: 'WorkflowsMap',
		contextComponent: 'WorkflowsMap',
	},
};

const combined = { StaticRoutes, routes };

export default combined;
