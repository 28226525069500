import * as roles from 'core/model/roles';

/**
 * Static routes
 */

// Admin
const Groups = '/admin/groups';
const GroupsDefinition = '/admin/groups/definition';
const GroupsAssign = '/admin/groups/assign';
const GroupsReport = '/admin/groups/report';

export const StaticRoutes = {
	Groups,
	GroupsDefinition,
	GroupsAssign,
	GroupsReport,
};

/**
 * Dynamic routes
 */
// const EditFormsDynamic = EditForms + '/form/:form';
// const EditWorkflowsDynamic = EditWorkflows + '/workflow/:workflow';
// const PdfTemplateDynamic = PdfTemplates + '/workflow/:workflow';

// export const DynamicRoutes = {
// 	EditWorkflows: EditWorkflowsDynamic,
// 	EditForms: EditFormsDynamic,
// 	PdfTemplates: PdfTemplateDynamic,
// };

export const routes = {
	[Groups]: {
		name: 'grouping',
		to: GroupsDefinition,
		role: roles.EDITOR,
		exact: true
	},
	[GroupsDefinition]: {
		name: 'definition',
		component: 'Grouping',
		role: roles.EDITOR
	},
	[GroupsAssign]: {
		name: 'assignation',
		component: 'BulkAssign',
		role: roles.EDITOR
	},
	[GroupsReport]: {
		name: 'report',
		component: 'GroupReport',
		role: roles.EDITOR
	}
};

const combined = {StaticRoutes, routes};

export default combined;
