import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane, Table } from 'reactstrap';
import { FormattedDate } from 'react-intl';
import { Link } from 'react-router-dom';

import { DynamicRoutes } from '../..//model/routes';
import { buildPath } from 'core/model/lib/urlTools';
import T from 'modules/i18n';
import { FileTab } from '../sidebars';

class Apply extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeTab: '1'
		};
		this.activeRef = React.createRef();

		this.toggle = this.toggle.bind(this);
		this.stopPropagation = this.stopPropagation.bind(this);
	}

	toggle(tab) {
		if (this.state.activeTab !== tab)
			this.setState({activeTab: tab});
	}

	stopPropagation(event) {
		event.stopPropagation();
	}

	componentDidMount() {
		document.addEventListener("mousemove", this.stopPropagation);
	}

	componentDidUpdate(prevProps) {
		this.activeRef.current && this.activeRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
	}

	componentWillUnmount() {
		document.removeEventListener("mousemove", this.stopPropagation);
	}

	render() {
		const { history } = this.props.data;
		const { application } = this.props;
		const { uuid } = this.props.application;
		if (typeof history == 'undefined')
			return null;

		const current_node = this.props.data.node.mname;
		return (
			<React.Fragment>
				<Nav tabs>
					<NavItem>
						<NavLink
							className={this.state.activeTab==='0' ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggle('0'); }}
						>
							<T>details</T>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={this.state.activeTab==='1' ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggle('1'); }}
						>
							<T>history</T>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={this.state.activeTab==='2' ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggle('2'); }}
						>
							<T>files</T>
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={this.state.activeTab} className="scroller mx-2 mt-2">
					<TabPane tabId="0">
						<Table>
							<thead>
								<tr>
									<th colSpan={2} className="text-center">{application.workflow}</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th className="px-0 small"><T>registry_number</T></th>
									<td>{application.registry_number}</td>
								</tr>
								<tr>
									<th className="px-0 small"><T>arithmos_fakelou</T></th>
									<td>{application.arithmos_fakelou}</td>
								</tr>
								<tr>
									<th className="px-0 small"><T>defteros_arithmos_fakelou</T></th>
									<td>{application.defteros_arithmos_fakelou}</td>
								</tr>
								<tr>
									<th className="px-0 small"><T>author</T></th>
									<td>{application.author}</td>
								</tr>
								<tr>
									<th className="px-0 small"><T>created</T></th>
									<td>
										{application.created &&
											<FormattedDate
												value={application.created}
												year='numeric'
												month='short'
												day='2-digit'
											/>
										}
									</td>
								</tr>
								<tr>
									<th className="px-0 small"><T>last_action</T></th>
									<td>
										{application.last_action &&
											<FormattedDate
												value={application.last_action}
												year='numeric'
												month='short'
												day='2-digit'
											/>
										}
									</td>
								</tr>
								<tr>
									<th className="px-0 small"><T>revision</T></th>
									<td>
										{application.revision}
										{ application.parent &&
											<React.Fragment>
												<span> - </span>
												<Link to={buildPath(DynamicRoutes.Apply, [application.parent, 'current'])} target="_blank">
													<T>parent</T>
												</Link>
											</React.Fragment>
										}
									</td>
								</tr>
								<tr>
									<th className="px-0 small"><T>step</T></th>
									<td>{application.step}</td>
								</tr>
								<tr>
									<th className="px-0 small"><T>reviewer</T></th>
									<td>{application.reviewer}</td>
								</tr>
								<tr>
									<th className="px-0 small"><T>status</T></th>
									<td><T>{application.status}</T></td>
								</tr>
							</tbody>
						</Table>
					</TabPane>
					<TabPane tabId="1">
						<Nav vertical className="pb-3">
							{
								Object.keys(history).map((node) => {
									let label = history[node];
									let node_path = buildPath(DynamicRoutes.Apply, [uuid, node]);
									let active_class = current_node===node ? 'text-warning' : undefined;
									return (
										<div key={`link_${node}`} ref={current_node===node && this.activeRef}>
											<NavItem>
												<NavLink
													tag={Link}
													to={node_path}
													disabled={current_node===node}
													className={`${active_class} pb-0 pt-1 px-1`}
												>
													{current_node===node && <i className="fa fa-hand-o-right mr-2" />}
													{label}
												</NavLink>
											</NavItem>
										</div>
									);
								})
							}
						</Nav>
					</TabPane>
					<TabPane tabId="2">
						<FileTab uuid={uuid} />
					</TabPane>
				</TabContent>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	data: state.list.apply.data,
	application: state.list.application.data,
});

Apply = connect(mapStateToProps)(Apply);

export default Apply;
